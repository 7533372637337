body {
  overflow-x: hidden;
}

.makeIconWhite .MuiSvgIcon-root {
  color: white;
}

.MuiAccordion-root::before {
  background-color: white !important;
}

.MuiTab-root {
  max-width: 100% !important;
}

.noTopPadding {
  padding-top: 0 !important;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  right: 0;
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 8px;
  width: 12px;
  height: 12px;
}

.rules li {
  padding-bottom: 1rem;
}

.rules li::marker {
  font-weight: bold;
}

widgetbot-crate {
  visibility: hidden;
}
